<template>
    <div>
        <v-btn
            color="primary"
            elevation="0"
            v-if="!userCompleted"
            @click="completeCourse()"
            :loading="completionLoad"
        >Markér gennemført
        </v-btn>
        <v-btn
            v-else
            color="green"
            dark
            elevation="0"
            @click="completeCourse()"
            :loading="completionLoad"
        >
            <v-icon>mdi-seal</v-icon>
            <span>Gennemført</span>
        </v-btn>
    </div>
</template>

<script>
    import api from "../../services/api";

    export default {
        name: "completeCourseButton",
        data() {
            return {
                userCompleted: false,
                completionLoad: false,
            }
        },
        methods: {
            getCompleted() {
                api.get('course/completed/' + this.$router.currentRoute.params.id)
                    .then(response => {
                        const r = response.data
                        if (!r) {
                            this.userCompleted = false
                        } else {
                            let completed = response.data.find(x => x.userId == this.$store.state.auth.user.id)
                            if (typeof completed === 'undefined') {
                                this.userCompleted = false
                            } else {
                                this.userCompleted = completed.userId == this.$store.state.auth.user.id ? true : false
                            }
                        }
                    })
            },
            completeCourse() {
                this.completionLoad = true
                api.post('course/completed/' + this.$router.currentRoute.params.id + '/' + this.$store.state.auth.user.id)
                    .then(() => {
                        this.getCompleted()
                    })
                    .then(() => {
                        this.completionLoad = false
                    })
            },
        },
        mounted() {
            this.getCompleted()
        }
    }
</script>

<style scoped>

</style>