<template>
    <v-card dense elevation="0">
        <v-toolbar color="primary" dark dense elevation="0">
            <span>Registrer tidsforbrug</span>
        </v-toolbar>
        <v-card-text class="pt-6">
            <v-row>
                <v-col class="col-12 col-md-6">
                    <v-text-field
                        label="Note: fx. 'Startet'"
                        autofocus
                        hide-details
                        dense
                        flat
                        single-line
                        prepend-inner-icon="mdi-note-text"
                        @click:prepend-inner="textarea = !textarea"
                        v-if="!textarea"
                        v-model="newTimeSpent.note"
                        @keydown.enter="addTimeSpent"
                        name="timeNote"
                    />
                    <v-textarea
                        label="Note: fx. 'Startet'"
                        hide-details
                        dense
                        flat
                        single-line
                        prepend-inner-icon="mdi-note-text"
                        @click:prepend-inner="textarea = !textarea"
                        v-if="textarea"
                        v-model="newTimeSpent.note"
                        name="timeNote"
                    >
                    </v-textarea>
                </v-col>
                <v-col class="col-12 col-md-2">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                label="fx. 1 time"
                                placeholder=""
                                hide-details
                                dense
                                flat
                                single-line
                                class="col-1 float-left"
                                v-model="newTimeSpent.timeSpent"
                                @keydown.enter="addTimeSpent"
                                v-bind="attrs"
                                v-on="on"
                                name="timespent"

                            />
                        </template>
                        <span>Antager timer, skriv fx. "45 min" for at registrere i minutter</span>
                    </v-tooltip>
                </v-col>
                <v-col class="col-12 col-md-2">
                    <v-menu
                        v-model="dpMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="computedDateFormatted"
                                label="Dato"
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                                single-line
                                dense
                                flat
                                class="col-2"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="newTimeSpent.taskDate"
                            @input="dpMenu = false"
                            first-day-of-week="1"
                            locale="da"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col class="col-12 col-md-2">
                    <v-btn
                        @click="addTimeSpent"
                        color="primary"
                        small
                        elevation="0"
                        width="100%"
                    >Tilføj
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-divider/>
        <v-data-table
            :items="timeSpent"
            :headers="headers"
            sort-desc
            sort-by="taskDate"
            :loading="timeSpentLoad"
        >
            <template v-slot:item.userId="{item}">
                <router-link :to="{name:'Profil',params:{id:item.userId}}">{{item.userName}}</router-link>
            </template>
            <template v-slot:item.createdDate="{item}">
                {{formatDate(item.createdDate)}}
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    import api from "../services/api";

    export default {
        name: "RegisterTimeSpent",
        data() {
            return {
                noteField: '',
                textarea: false,
                dpMenu: false,
                dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
                timeSpent: [],
                headers: [
                    {text: 'Note', value: 'note'},
                    {text: 'Tid', value: 'timeSpent', width: 120},
                    {text: 'Bruger', value: 'userId', width: 220},
                    {text: 'Dato', value: 'taskDate', width: 120,},
                ],
                newTimeSpent: {
                    course: this.$router.currentRoute.params.id,
                    note: '',
                    timeSpent: '',
                    taskDate: new Date().toISOString().substr(0, 10),
                },
                timeSpentLoad: false,

            }
        },
        methods: {
            formatDate(date) {
                if (!date) return null
                const [year, month, day] = date.split('-')
                return `${day}-${month}-${year}`
            },
            parseDate(date) {
                if (!date) return null
                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
            getTimeSpent() {
                api.get('course/time/' + this.$router.currentRoute.params.id)
                    .then(response => {
                        this.timeSpent = response.data
                    })
            },
            addTimeSpent() {
                this.timeSpentLoad = true
                api.post('course/time/add', {params: this.newTimeSpent})
                    .then(response => {
                        if (response.data === true) {
                            this.getTimeSpent()
                            this.newTimeSpent = {
                                course: this.$router.currentRoute.params.id,
                                note: '',
                                timeSpent: '',
                                taskDate: new Date().toISOString().substr(0, 10),

                            }
                            this.$store.dispatch("snackBar/snackSuccess", 'Note tilføjet')
                        } else if (typeof response.data.error !== 'undefined') {
                            this.$store.dispatch("snackBar/snackAlert", response.data.error)
                        }
                    })
                    .then(() => {
                        this.timeSpentLoad = false
                    })
            }
        },
        mounted() {
            this.getTimeSpent()
        },
        computed: {
            computedDateFormatted() {
                return this.formatDate(this.newTimeSpent.taskDate)
            },
        }
    }
</script>

<style scoped>

</style>