var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"dense":"","elevation":"0"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","dense":"","elevation":"0"}},[_c('span',[_vm._v("Registrer tidsforbrug")])]),_c('v-card-text',{staticClass:"pt-6"},[_c('v-row',[_c('v-col',{staticClass:"col-12 col-md-6"},[(!_vm.textarea)?_c('v-text-field',{attrs:{"label":"Note: fx. 'Startet'","autofocus":"","hide-details":"","dense":"","flat":"","single-line":"","prepend-inner-icon":"mdi-note-text","name":"timeNote"},on:{"click:prepend-inner":function($event){_vm.textarea = !_vm.textarea},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addTimeSpent($event)}},model:{value:(_vm.newTimeSpent.note),callback:function ($$v) {_vm.$set(_vm.newTimeSpent, "note", $$v)},expression:"newTimeSpent.note"}}):_vm._e(),(_vm.textarea)?_c('v-textarea',{attrs:{"label":"Note: fx. 'Startet'","hide-details":"","dense":"","flat":"","single-line":"","prepend-inner-icon":"mdi-note-text","name":"timeNote"},on:{"click:prepend-inner":function($event){_vm.textarea = !_vm.textarea}},model:{value:(_vm.newTimeSpent.note),callback:function ($$v) {_vm.$set(_vm.newTimeSpent, "note", $$v)},expression:"newTimeSpent.note"}}):_vm._e()],1),_c('v-col',{staticClass:"col-12 col-md-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"col-1 float-left",attrs:{"label":"fx. 1 time","placeholder":"","hide-details":"","dense":"","flat":"","single-line":"","name":"timespent"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addTimeSpent($event)}},model:{value:(_vm.newTimeSpent.timeSpent),callback:function ($$v) {_vm.$set(_vm.newTimeSpent, "timeSpent", $$v)},expression:"newTimeSpent.timeSpent"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("Antager timer, skriv fx. \"45 min\" for at registrere i minutter")])])],1),_c('v-col',{staticClass:"col-12 col-md-2"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"col-2",attrs:{"label":"Dato","hide-details":"","single-line":"","dense":"","flat":""},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dpMenu),callback:function ($$v) {_vm.dpMenu=$$v},expression:"dpMenu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","locale":"da"},on:{"input":function($event){_vm.dpMenu = false}},model:{value:(_vm.newTimeSpent.taskDate),callback:function ($$v) {_vm.$set(_vm.newTimeSpent, "taskDate", $$v)},expression:"newTimeSpent.taskDate"}})],1)],1),_c('v-col',{staticClass:"col-12 col-md-2"},[_c('v-btn',{attrs:{"color":"primary","small":"","elevation":"0","width":"100%"},on:{"click":_vm.addTimeSpent}},[_vm._v("Tilføj ")])],1)],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"items":_vm.timeSpent,"headers":_vm.headers,"sort-desc":"","sort-by":"taskDate","loading":_vm.timeSpentLoad},scopedSlots:_vm._u([{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name:'Profil',params:{id:item.userId}}}},[_vm._v(_vm._s(item.userName))])]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdDate))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }