<template>
    <v-container class="col-lg-8 col-md-12 col-xs-12 col-sm-12">
        <v-card class="mb-5" dense elevation="0">
            <v-toolbar color="primary" dark dense elevation="0">
                <span>
                    <v-text-field
                        v-if="edit"
                        class="pa-0 ma-0"
                        v-model="course.name"
                        single-line
                        outlined
                        hide-details
                    />
                    <span v-else>{{course.name}}</span>
                </span>
                <v-spacer/>
                <v-btn
                    v-if="edit"
                    icon
                    @click="editCourse('cancel')"
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                    v-else
                    icon
                    @click="editCourse()"
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="text--primary">

                <div v-if="edit">
                    <v-alert
                        border="left"
                        colored-border
                        type="error"
                        elevation="2"
                    >Funktion midlertidig slået fra
                    </v-alert>
                    <v-textarea label="Beskrivelse" outlined v-model="course.description"/>
                    <v-text-field label="Url" outlined v-model="course.url"/>
                    <v-select
                        label="Kategori"
                        :items="courseTypes"
                        item-text="name"
                        item-value="id"
                        outlined
                        v-model="courseTypeSelected"
                    />
                    <v-text-field label="Estimeret tidsforbrug" outlined v-model="course.estimated"/>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn
                            @click="editCourse('save')"
                            color="primary">
                            <v-icon>mdi-save</v-icon>
                            Gem
                        </v-btn>
                        <v-btn
                            @click="editCourse('cancel')"
                            color="red"
                            outlined>
                            <v-icon>mdi-save</v-icon>
                            Annuller
                        </v-btn>
                    </v-card-actions>
                </div>
                <div v-else>
                    <h3>Beskrivelse</h3>
                    <div v-html="course.description"></div>
                    <p></p>
                    <h3>Url</h3>
                    <p><a :href="course.url" target="_blank" rel="noopener,noreferrer">{{course.url}}</a></p>
                    <h3>Kategori</h3>
                    <p>{{courseType(course.courseType)}}</p>
                    <h3>Estimeret tidsforbrug</h3>
                    <p>{{course.estimated}}</p>
                </div>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-rating
                    v-model="rating"
                    hover
                    color="yellow darken-2"
                    background-color="yellow darken-2"
                ></v-rating>
                <span class="grey--text caption mr-2">
                    ({{ rating }})
                </span>
                <v-spacer></v-spacer>
                <complete-course-button/>
            </v-card-actions>
        </v-card>
        <register-time-spent/>
    </v-container>
</template>

<script>
    import api from "../services/api";
    import RegisterTimeSpent from "../components/RegisterTimeSpent";
    import CompleteCourseButton from "../components/course/completeCourseButton";

    export default {
        name: "Course",
        components: {CompleteCourseButton, RegisterTimeSpent},
        data() {
            return {
                course: [],
                courseTypes: [],
                courseTypeSelected: 0,
                edit: false,
                ratingLoad: false,
            }
        },
        methods: {
            getCourse() {
                api.get('course/' + this.$router.currentRoute.params.id)
                    .then(response => {
                        this.course = response.data.length >= 1 ? response.data[0] : '404 - Ikke fundet'
                        this.courseTypeSelected = this.course.courseType
                    })
            },
            getCourseTypes() {
                api.get('course/types')
                    .then(response => {
                        this.courseTypes = response.data
                    }).then()
            },
            courseType(ctid) {
                let course = this.courseTypes.find(x => x.id == ctid)
                if (typeof course !== 'undefined') {
                    return course.name
                }
                return 'bugga'
            },
            editCourse(action) {
                this.edit = !this.edit
                switch (action) {
                    case 'save':
                        this.$store.dispatch('snackBar/snackAlert', 'Funktionalitet ikke implementeret endnu')
                        break;
                    case 'cancel':
                        this.$store.dispatch('snackBar/snackMsg', 'Annulleret')
                        break;
                    default:
                }
            },
            completeCourse() {
                this.completionLoad = true
                api.post('course/completed/' + this.$router.currentRoute.params.id + '/' + this.$store.state.auth.user.id)
                    .then(response => {
                        this.getCompleted()
                    })
                    .then(() => {
                        this.completionLoad = false
                    })
            },
        },
        mounted() {
            this.getCourse()
            this.getCourseTypes()
        },
        computed: {
            rating: {
                get: function () {
                    if (this.course.ratings) {
                        return (this.course.ratings.reduce((s, v) => {
                            return s + v.rating
                        }, 0) / this.course.ratings.length).toFixed(1)
                    }
                    return 0
                },
                set: function (newRating) {
                    if (this.ratingLoad) {
                        return
                    }
                    this.ratingLoad = true
                    let userVoted = !!(this.course.ratings && typeof this.course.ratings.find(x => x.userId == this.$store.state.auth.user.id) !== 'undefined')
                    let params = {
                        course: this.$router.currentRoute.params.id,
                        rating: newRating
                    }
                    if (userVoted) {
                        api.put('course/rating', params)
                            .then(response => {
                                console.log(response.data)
                                if (response.data === true) {
                                    this.$store.dispatch('snackBar/snackSuccess', 'Andmeldelse rettet til: ' + params.rating)
                                    this.getCourse()
                                }
                            })
                            .then(() => {
                                this.ratingLoad = false
                            })
                    } else {
                        api.post('course/rating', params)
                            .then(response => {
                                if (response.data === true) {
                                    this.$store.dispatch('snackBar/snackSuccess', 'Andmeldelse: ' + params.rating + ' registreret')
                                    this.getCourse()
                                }
                            })
                            .then(() => {
                                this.ratingLoad = false
                            })
                    }

                }
            },
        },
    }
</script>

<style scoped>

</style>